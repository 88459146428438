import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
import root from '@/minxin/root'

const Index = {
  name: 'SalesAmountReport',
  mixins: [tableMixin, root],
  components: {
    PageHeader
  },
  data() {
    return {
      root_name: 'xsjebb',
      root_msg: '销售金额报表',
      visible: false,
      formSearch: {
        PageIndex: 1,
        PageSize: 10,
        KeyWords: '',
        OrderType: 1, // 销售金额报表
        ReportType: 1 //  1：按金额查询 2：按物料查询
      },
      searchWords: {
        KeyWords: ''
      },
      selects: [],
      selectsArr: []
    }
  },
  watch: {
    selects(a) {
      this.selectsArr = []
      if (a.length === 0) return this.selectsArr = []
      a.map(item => {
        this.selectsArr.push(item.IDX)
      })
      console.log(this.selectsArr)
    }
  },
  methods: {
    selectAll(e) {
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    // 导出
    exportExel() {
      const _this = this
      _this.tableDataloading = true
      this.formSearch.FieldStr = JSON.stringify(this.tableListJson[ this.$refs.FieldManagement.name ])
      this.formSearch.SelectIDList = this.selectsArr
      this.$refs.ExportLoading.open()
      this.$api.ExportSalesOrderPriceReportByUserField(_this.formSearch).then((res) => {
        _this.tableDataloading = false
        if (res.RetCode === '0') {
          // _this.downloadFile(res.Message, _this.guid() + '.xlsx')
          const name = this.$minCommon.getExportName('销售金额报表')
          this.$refs.ExportLoading.success(res.Message, name)
          return this.$minCommon.downloadFile(res.Message, name)
        } else {
          this.$refs.ExportLoading.close()
          return _this.$message.error('导出数据出错：' + res.RetMsg)
        }
      })
    },
    viewOrder(row) {
      this.$router.push({ name: 'orderDetails1', params: { Ishandler: '-1', orderid: row.OrderID, Purchase: true }})
    },
    selectType(type) {
      this.formSearch.ReportType = type
      this.visible = false
      this.currentPage = 1
      this.getTableDataAsync()
    },
    async getTableDataAsync(page, pageSize) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      const response = await this.$api.GetSalesOrderPriceReport(this.formSearch)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      console.log('response', response)
      return response
    }
  }
}
export default Index
